<template>
    <div class="html-default">
        <table border="1" class="mytable mytable-head">
            <thead>
                <tr>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Kode Produksi</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Tanggal Awal Produksi</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Status</label></td>
                    <td class="px-3" width="10%" style="text-align: center;"><label>Total Pembuangan</label></td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in items" :key="index">
                    <td class="px-2"><label>{{ item['production_code'] }}</label></td>
                    <td class="px-2">
                        <label>{{ item['production_start_date'] == null ? '-' :  
                            new Date(item['production_start_date']).toLocaleDateString('es-CL') }}
                        </label>
                    </td>
                    <td class="px-2"><label>{{ item['status'] }}</label></td>
                    <td class="px-2"><label>{{ item['production_reject_quantity'] }}</label></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import moment from 'moment';
import { response } from '../../infrastructure/constant/response';
import productionReportService from '../../Areas/Reporting/ProductionReport/Script/ProductionReportService';

export default {
    name: 'ProductionReportHtml',
    data(){
        return{
            StartDate: this.$route.params.startdate,
            EndDate: this.$route.params.enddate,
            items: [],
        }
    },
    async mounted() {
        const variables = {
            startDate: this.StartDate,
            endDate: this.EndDate
        }
        var data = await productionReportService.getProductionReportQuery(variables);
        var itemData = productionReportService.itemPDF(data)
        this.items = itemData;
    }
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
</style>